import React from 'react';
import { connect } from 'react-redux';

import TodoList from '../components/TodoList';

import '../style/_common.scss';

// import gql from 'graphql-tag';

class PrintPage extends React.Component {
  render() {
    return (
      <div className="wide PrintPage">
        <TodoList print />
      </div>
    );
  }
}

const mstp = state => ({
  saveProgressModal: state.saveProgressModal,
  pdfModal: state.pdfModal,
  trelloModal: state.trelloModal
});

export default connect(mstp)(PrintPage);
